<template>
  <div class="event-item-image">
    <div class="event-item-image__header text-tetriary">{{ $tf('event') }}</div>
    <div ref="container" class="event-item-image__content" v-loading="loading">
      <div v-if="item" class="event-item-image__content-image" :style="contentStyle">
        <img ref="image" :src="item.fullframe" draggable="false" />
        <div v-for="bbox in bboxes" class="event-item-image__rect" :style="getBBoxStyle(bbox)" />
      </div>
      <div class="event-item-image__box" v-else-if="error">
        <i class="event-item-image__error-icon el-icon-close"></i>
      </div>
      <div class="event-item-image__box" v-else>
        {{ $tf('loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import ObjectThumbnail from '../objects/thumbnail.vue';
import { Watch, Component } from 'vue-property-decorator';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import { Debounce } from '@/apps/common/decorators/lodash';

@Component({
  name: 'EventItemImage',
  props: {
    id: {
      type: String,
      required: true
    },
    objects: {
      type: String,
      default: 'faces'
    }
  },
  components: {
    ObjectThumbnail
  }
})
export default class EventItemImage extends Component {
  item = null;
  loading = false;
  error = null;
  imageScale = 0.1;

  mounted() {
    this.load();
  }

  get stateName() {
    return `${this.objects}_events`;
  }

  get state() {
    return this.$store.state[this.stateName];
  }

  @Watch('id')
  idChangeHandler() {
    this.load();
  }

  @Debounce()
  load() {
    const id = (this.id || '').trim();
    this.clear();

    if (!id) return;

    this.loading = true;
    this.$store
      .dispatch(this.state.Action.Get, { id })
      .then((v) => {
        this.item = v;
        this.$nextTick(this.loadImage);
        this.$emit('change', ObjectsTypeSingleForm[this.objects] + 'event:' + this.item.id);
      })
      .catch((e) => {
        this.error = e;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  loadImage() {
    let image = this.$refs.image;
    let container = this.$refs.container;
    const { height, width } = container.getBoundingClientRect();
    image.onload = () => {
      this.sourceWidth = image.naturalWidth;
      this.sourceHeight = image.naturalHeight;
      image.onload = null;
      this.imageScale = Math.min(width / image.naturalWidth, height / image.naturalHeight);
    };
  }

  get bboxes() {
    return this.item ? [this.getBox(this.item)] : [];
  }

  getBBoxStyle(box) {
    return {
      width: `${box.w}px`,
      height: `${box.h}px`,
      left: `${box.x}px`,
      top: `${box.y}px`
    };
  }

  get contentStyle() {
    return { transform: `scale(${this.imageScale})` };
  }

  getBox(event) {
    return {
      x: event.frame_coords_left,
      y: event.frame_coords_top,
      w: event.frame_coords_right - event.frame_coords_left,
      h: event.frame_coords_bottom - event.frame_coords_top
    };
  }

  clear() {
    this.item = null;
    this.error = null;
    this.loading = false;
  }
}
</script>

<style lang="stylus">

.event-item-image {
  position: relative;
  width: 160px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &__content {
    background-color: vars.background_colors.info;
    height: 480px;
    width: 480px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content-image {
    position: relative;
  }

  &__rect {
    position: absolute;
    border: 4px solid #00f900;
  }

  &__header {
    margin-bottom: 0.5rem;
  }

  &__box {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__error-icon {
    font-size: 2rem;
  }
}
</style>
