<template>
  <div>
    <div class="cols content-header">
      <div>
        <h2 class="flex-cell--static">{{ $tf('verify') }}</h2>
      </div>
      <div class="flex-cell--static pad-left--2 content-header-tabs">
        <common-tabs v-model="objects" :items="objectTabs" :converter="$tfo" />
      </div>
    </div>

    <div class="cols-multi--center" v-loading="loading">
      <el-form
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        :name="$options.name"
        class="source-form"
      >
        <el-form-item :label="$tf('source') + ' 1'">
          <el-input name="event-id" :placeholder="$tf('event_id')" type="text" v-model="eventId1" style="width: 12rem" clearable></el-input>
          <span class="input--text-info">{{ $tt('or') }}</span>
        </el-form-item>
        <el-form-item>
          <file-objects-selector ref="fileSelector1" :objects="objects" :features="{}" @change="(v) => detectHandler(1, v)"></file-objects-selector>
          <event-item-image ref="imageSelector1" :objects="objects" :id="eventId1" v-if="eventId1" @change="(v) => eventHandler(1, v)"></event-item-image>
        </el-form-item>
        <el-form-item>
          {{ filter.object1 }}
        </el-form-item>
      </el-form>
      <el-form
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        :name="$options.name"
        class="source-form"
      >
        <el-form-item :label="$tf('source') + ' 2'">
          <el-input name="event-id" :placeholder="$tf('event_id')" type="text" v-model="eventId2" clearable style="width: 12rem"></el-input>
          <span class="input--text-info">{{ $tt('or') }}</span>
        </el-form-item>
        <el-form-item>
          <file-objects-selector :objects="objects" ref="fileSelector2" :features="{}" @change="(v) => detectHandler(2, v)"></file-objects-selector>
          <event-item-image ref="imageSelector2" :objects="objects" :id="eventId2" v-if="eventId2" @change="(v) => eventHandler(2, v)"></event-item-image>
        </el-form-item>
        <el-form-item>
          {{ filter.object2 }}
        </el-form-item>
        <el-form-item>
          <el-button name="verify-btn" type="primary" @click="verify" :disabled="!(filter.object1 && filter.object2)" v-loading="state.loading">{{
            $tf('verify_v')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      name="verify-result-dialog"
      :title="$tfo('result')"
      :visible.sync="resultVisible"
      :modal="true"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :show-close="true"
      :width="'50%'"
    >
      <div style="margin: 2rem" class="center-text">
        <h1>{{ state.confidence | floatToPercent }}</h1>
      </div>
      <p class="center-text">
        <el-button name="ok-btn" type="primary" @click="(v) => (resultVisible = false)">{{ $tfo('ok') }}</el-button>
      </p>
    </el-dialog>
  </div>
</template>
<script>
import FileObjectsSelector from '../search/objects/selectors/file';
import EventItemImage from '../events/item.image';

export default {
  name: 'page-verify',
  components: {
    EventItemImage,
    FileObjectsSelector
  },
  data: function () {
    return {
      objects: 'faces',
      loading: false,

      eventId1: '',
      eventId2: '',

      filter: {
        object1: '',
        object2: ''
      },

      resultVisible: false
    };
  },
  computed: {
    state() {
      return this.$store.state.verify;
    },
    objectTabs() {
      return this.$store.getters.enabledObjects.map((item) => {
        return { i18n: item, name: item };
      });
    }
  },
  watch: {
    objects: function (v, p) {
      this.eventId1 = '';
      this.eventId2 = '';
      this.$refs['fileSelector1']?.reset();
      this.$refs['fileSelector2']?.reset();
      this.filter.object1 = '';
      this.filter.object2 = '';
    },
    eventId1: function (v, p) {
      if (v) {
        this.$refs['fileSelector1']?.reset();
      }
      this.filter.object1 = '';
    },
    eventId2: function (v, p) {
      if (v) {
        this.$refs['fileSelector2']?.reset();
      }
      this.filter.object2 = '';
    }
  },
  mounted() {},
  methods: {
    detectHandler(index, v) {
      this['eventId' + index] = '';
      this.filter[`object${index}`] = v.detection;
    },
    eventHandler(index, v) {
      this.filter[`object${index}`] = v;
      this.$refs[`fileSelector${index}`]?.reset();
    },
    verify() {
      this.$store
        .dispatch('verify', this.filter)
        .then((v) => {
          this.resultVisible = true;
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    reset() {}
  }
};
</script>
<style>
.source-form {
  display: inline-block;
  width: 520px;
}
</style>
